.auth-page {
    background: #fff url(../../images/background.jpg) no-repeat;
    background-size: cover;
}

.login-box-body {
    background: none;
}

.btn {
    padding: 3px 6px !important;
}

.alert {
    margin: 0 !important;
    border-radius: 0;
    text-align: center;
    font-weight: bold;
}

.navbar-nav > .user-menu > .dropdown-menu {
    padding: 0;
}

.form-control {
    border-radius: 0 !important;
}

.select2-selection__rendered {
    line-height: 20px !important;
    margin-top: 0 !important;
    padding-left: 0 !important;
}

.select2-selection {
    height: 34px !important;
}

.jFiler-theme-default .jFiler-input {
    border-radius: 0 !important;
    width: 100% !important;
}

.jFiler-item-others {
    display: none;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 32px;
}

.btn-circle {
    width: 21px;
    height: 21px;
    text-align: center;
    padding: 1px 5px !important;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 15px;
}

tr.selected td {
    background: #d5d296;
}

.box-footer .pagination {
    margin: 0;
}